<template>
  <div class="documents-as-grid">
    <div class="_grid-box">
      <div class="_doc" v-for="doc in this.$store.getters.getDocuments" :key="doc.id">
        <div class="_date">
          {{doc.publish_date}}
        </div>
        <div class="_title">
          {{doc.name}}
        </div>
        <a :href="doc.file_url" class="btn btn--arr-down">
          Скачать файл
          <span class="arrow arrow--down"><span></span></span>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DocumentsGrid',
  
  data() {
    return {
      
    }
  }
}
</script>