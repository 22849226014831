var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tabs-slider"},[_c('div',{staticClass:"tabs-nav"},_vm._l((_vm.texts),function(block,i){return _c('div',{key:i,class:['_tab', _vm.currentTextNum==i?'active':''],on:{"click":function($event){return _vm.selectTab(i)}}},[_vm._v(" "+_vm._s(block.name)+" ")])}),0),_c('div',{ref:"tabsContent",staticClass:"tabs-content",style:({
      'min-height': _vm.tabsMinHeight+'px'
    })},[_c('transition-group',{attrs:{"name":"texts"}},_vm._l(([_vm.currentTextNum]),function(number){return _c('div',{key:number},[_c('div',{staticClass:"texts",domProps:{"innerHTML":_vm._s(_vm.texts[number].text)}}),_c('transition',{attrs:{"name":"fade-fastly"}},[(_vm.texts[number].text2 && _vm.fullTexts.indexOf(number)!==-1)?_c('div',{staticClass:"texts",domProps:{"innerHTML":_vm._s(_vm.texts[number].text2)}}):_vm._e()]),(_vm.texts[number].text2 && _vm.fullTexts.indexOf(number)==-1)?_c('div',{staticClass:"_more-text"},[_c('span',{staticClass:"btn",on:{"click":function($event){return _vm.openFullText(number)}}},[_vm._v("Читать дальше")])]):_vm._e()],1)}),0)],1),_c('div',{staticClass:"slider-wrapper agile-wrapper"},[(_vm.photos.length>1)?_c('div',{staticClass:"slider-hover slider-hover--left",on:{"click":function($event){return _vm.agileMove('left')},"mousemove":_vm.updateArrowCoordinates}},[_c('span',{staticClass:"_cursor-arrow",style:({left: _vm.cursorArrowCoords.x+'px', top: _vm.cursorArrowCoords.y+'px'})},[_vm._m(0)])]):_vm._e(),(_vm.photos.length>1)?_c('div',{staticClass:"slider-hover slider-hover--right",on:{"click":function($event){return _vm.agileMove('right')},"mousemove":_vm.updateArrowCoordinates}},[_c('span',{staticClass:"_cursor-arrow",style:({left: _vm.cursorArrowCoords.x+'px', top: _vm.cursorArrowCoords.y+'px'})},[_vm._m(1)])]):_vm._e(),(_vm.photos.length>1)?_c('agile',{ref:"agileSliderRef",attrs:{"dots":false,"nav-buttons":false}},_vm._l((_vm.photos),function(photo,i){return _c('div',{key:i,staticClass:"slide _slide"},[_c('img',{attrs:{"src":photo.src}}),_c('div',{staticClass:"_slide-info"},[_c('span',{staticClass:"_num"},[_vm._v(_vm._s(i+1)+" ("+_vm._s(_vm.photos.length)+")")]),_vm._v(_vm._s(photo.title)+" ")])])}),0):_vm._e(),_c('div',{staticClass:"slider",staticStyle:{"display":"none"},on:{"touchstart":_vm.startSwipe,"touchend":_vm.endSwipe}},[_c('div',{staticClass:"_scroll",style:({
        marginLeft:
          _vm.mobView
            ? 'calc(' + (-1*_vm.currentNum*100) + 'vw ' + ' + ' + (48*_vm.currentNum) + 'px - ' + (16*_vm.currentNum) + 'px)'
            : 'calc(' + (-1*_vm.currentNum*70) + 'vw ' + ' - ' + (20*_vm.currentNum) + 'px)'
      })},[_vm._l((_vm.texts),function(block,i){return _c('div',{key:i,staticClass:"_slide"},[_c('img',{attrs:{"src":block.photo}}),_c('div',{staticClass:"_slide-info"},[_c('span',{staticClass:"_num"},[_vm._v(_vm._s(i+1)+" ("+_vm._s(_vm.texts.length)+")")]),_vm._v(_vm._s(block.title)+" ")])])}),(_vm.texts.length>1)?_c('div',{staticClass:"_slide",on:{"click":function($event){return _vm.selectTab(0)}}},[_c('img',{attrs:{"src":_vm.texts[0].photo}}),_c('div',{staticClass:"_slide-info"},[_c('span',{staticClass:"_num"},[_vm._v("1 ("+_vm._s(_vm.texts.length)+")")]),_vm._v(_vm._s(_vm.texts[0].title)+" ")])]):_vm._e()],2)])],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',{staticClass:"arrow"},[_c('span')])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',{staticClass:"arrow"},[_c('span')])}]

export { render, staticRenderFns }