<template>
  <div>
    <div class="table-filters-wrap">
      <div class="table-filters" v-click-outside="hideFilters" v-if="!$mobView">
        <div v-for="(filter, filter_num) in filters" :key="filter.id" class="_filter-column">
          <div
            :class="[
              '_selector',
              '_selector--' + filter.type,
              activeFilter == filter.id ? '--active' : '',
            ]"
          >
            <div class="_activator" @click="activateFilter(filter.id)">
              {{ filter.title }}
              <span class="arrow" v-if="!checkFilterFilled(filter_num)"><span></span></span>
              <span class="_val-view">{{ filterValuesView(filter_num) }}</span>
            </div>
            <div class="_values" v-if="filter.type == 'select'">
              <div
                v-for="v in filter.values"
                :key="v.id"
                :class="['_value', checkFilterValue(filter_num, v.id) ? 'active' : '']"
                @click="selectFilterValue(filter_num, filter.id, v.id)"
              >
                {{ v.title }}
              </div>
            </div>
            <div class="_values" v-if="filter.type == 'range'">
              <VueRangeSlider
                v-if="activeFilter == filter.id"
                :min="filters[filter_num].values[0]"
                :max="filters[filter_num].values[1]"
                :step="filters[filter_num].step"
                :height="33"
                :dot-width="21"
                :dot-height="33"
                :tooltip-merge="false"
                :real-time="true"
                :tooltip-style="{
                  background: 'none',
                  border: 0,
                  borderRadius: 0,
                  color: '#1C1C1A',
                  padding: 0,
                  top: '0px',
                }"
                :lazy="true"
                :formatter="formatRangeTooltip"
                v-model="filters[filter_num].selected"
              />
            </div>
          </div>
        </div>
      </div>
      <div v-else class="table-filters-mob">
        <div class="btn" @click="activateMobileFilters">
          Фильтры<span class="burger"><span></span></span>
        </div>
      </div>
    </div>

    <div class="aparts-table">
      <transition name="fade-fastly">
        <div class="_discard" v-if="filtersFilled()" @click="discardFilters">
          <span class="plus"></span>Сбросить фильтры
        </div>
      </transition>
      <div class="_row _row--empty">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div>
          <div
            :class="['_sorting', sortingByPrice == 'min-max' ? '--min-max' : '--max-min']"
            @click="changeSorting"
          >
            <span class="arrow arrow--down arrow--no-line"><span></span></span>Цена по возрастанию
          </div>
        </div>
      </div>

      <ApartTableRow
        v-for="flat in filteredFlats"
        :key="flat.id"
        :flat="flat"
        :activateFlat="activateFlat"
        :activeFlat="activeFlat"
        :discardActiveFlat="discardActiveFlat"
      />
    </div>

    <div
      v-if="filteredFlatsQty > filteredFlats.length"
      class="_show-more btn btn--light"
      @click="moreFlats"
    >
      Показать еще
    </div>

    <div class="mob-apart-info mob-apart-filters pop-up" v-if="$mobView && showMobFilters">
      <div class="_pop-up-content">
        <span class="plus" @click="activateMobileFilters"></span>
        <div class="pop-up__title">
          Фильтры
        </div>
        <div class="mob-apart-filters__filters table-filters">
          <div
            v-for="(filter, filter_num) in filters"
            :key="filter.id"
            :class="[
              '_filter',
              '_filter--' + filter.type,
              activeFilter == filter.id ? '--active' : '',
            ]"
          >
            <div>
              <div class="_activator" @click="activateFilter(filter.id)">
                {{ filter.title }}
                <span class="arrow"><span></span></span>
                <span class="_val-view" v-if="checkFilterFilled(filter_num)">{{
                  filterValuesView(filter_num)
                }}</span>
              </div>
              <div class="_values-wrap">
                <div class="_values" v-if="filter.type == 'select'">
                  <div
                    v-for="v in filter.values"
                    :key="v.id"
                    :class="['_value', checkFilterValue(filter_num, v.id) ? 'active' : '']"
                    @click="selectFilterValue(filter_num, filter.id, v.id)"
                  >
                    {{ v.title }}
                  </div>
                </div>
                <div class="_slider-view" v-if="filter.type == 'range'">
                  <vue-slider
                    v-if="activeFilter == filter.id"
                    v-model="filters[filter_num].selected"
                    :min="filters[filter_num].values[0]"
                    :max="filters[filter_num].values[1]"
                    :lazy="true"
                    :enable-cross="false"
                    :contained="false"
                    :tooltip="'always'"
                    :tooltip-style="{
                      background: 'none',
                      border: 0,
                      borderRadius: 0,
                      color: '#1C1C1A',
                      padding: 0,
                      top: '0px',
                    }"
                    :tooltip-formatter="formatRangeTooltip"
                    :height="33"
                    :dotSize="[21, 33]"
                  ></vue-slider>

                  <!--
                  <VueRangeSlider
                    v-if="activeFilter==filter.id"
                    :min="filters[filter_num].values[0]"
                    :max="filters[filter_num].values[1]"
                    :step="filters[filter_num].step"
                    :height="33"
                    :dot-width="21"
                    :dot-height="33"
                    :tooltip-merge="false"
                    :real-time="true"
                    :tooltip-style="{
                      background: 'none',
                      border: 0,
                      borderRadius: 0,
                      color: '#1C1C1A',
                      padding: 0,
                      top: '0px'
                    }"
                    :lazy="true"
                    :formatter="formatRangeTooltip"
                    v-model="filters[filter_num].selected"
                  />
                  -->
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="mob-apart-filters__buttons">
          <div class="btn active" @click="applyMobFilters">
            Применить фильтры<span class="arrow"><span></span></span>
          </div>
          <div class="btn" @click="discardFilters">
            Сбросить все фильтры<span class="close"><span></span></span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import Slider from '@/components/Slider.vue'
import ApartTableRow from "@/components/ApartTableRow.vue"

// import VueSimpleRangeSlider from 'vue-simple-range-slider';
// import 'vue-simple-range-slider/dist/vueSimpleRangeSlider.css'

import "vue-range-component/dist/vue-range-slider.css"
import VueRangeSlider from "vue-range-component"

// import VueSlider from 'vue-slider-component'
// import 'vue-slider-component/theme/default.css'

const flatsPerPage = 15

export default {
  name: "ApartsList",

  data() {
    return {
      activeFilter: 0,
      activeFlat: 0,
      activeFlatTab: 1,
      sortingByPrice: "min-max",
      showMobFilters: false,
      filters: [
        {
          id: 1,
          name: "block",
          title: "Особняк",
          type: "select",
          values: [
            { id: 1, title: "Особняк 1", value: 1 },
            { id: 2, title: "Особняк 2", value: 2 },
            { id: 3, title: "Особняк 3", value: 3 },
            { id: 4, title: "Особняк 4", value: 4 },
            { id: 5, title: "Особняк 5", value: 5 },
          ],
          selected: [],
        },
        {
          id: 2,
          name: "floor",
          title: "Этаж",
          type: "select",
          values: [
            // { id: 1, title: 'Этаж 1', value: 1 },
            { id: 2, title: "Этаж 2", value: 2 },
            { id: 3, title: "Этаж 3", value: 3 },
            { id: 4, title: "Этаж 4", value: 4 },
            { id: 5, title: "Этаж 5", value: 5 },
            { id: 6, title: "Этаж 6", value: 6 },
            { id: 7, title: "Этаж 7", value: 7 },
            { id: 8, title: "Этаж 8", value: 8 },
            { id: 9, title: "Этаж 9", value: 9 },
          ],
          selected: [],
        },
        {
          id: 3,
          name: "bedrooms",
          title: "Спальни",
          type: "select",
          values: [
            { id: 1, title: "1", value: 1 },
            { id: 2, title: "2", value: 2 },
            { id: 3, title: "3", value: 3 },
            { id: 4, title: "4", value: 4 },
          ],
          selected: [],
        },
        {
          id: 4,
          name: "area",
          title: "Площадь",
          type: "range",
          values: [64, 280],
          step: 1,
          selected: [64, 280],
        },
        {
          id: 5,
          name: "price",
          title: "Цена",
          type: "range",
          values: [30000000, 95000000],
          step: 1000000,
          selected: [30000000, 95000000],
        },
        {
          id: 6,
          name: "features",
          title: "Особенности",
          type: "select",
          values: [
            { id: 1, title: "Пентхаусы", value: "П" },
            { id: 2, title: "С террасой", value: "Т" },
          ],
          selected: [],
        },
      ],
      filtersCopy: [],
      flatsToShow: flatsPerPage,
      flats: [],
    }
  },

  watch: {
    // эта функция запускается при любом изменении вопроса
    filtersPriceRange: function(valAfter, valBefore) {
      if (!(valBefore[0] == valAfter[0] && valBefore[1] == valAfter[1])) {
        this.flatsToShow = flatsPerPage
      }
    },
  },

  computed: {
    filtersPriceRange() {
      return this.filters[4].selected
    },
    mobView() {
      return window.innerWidth <= 840 ? true : false
    },
    filteredFlats() {
      let filtersTmp = this.mobView ? this.filtersCopy : this.filters

      let flatsFiltered = this._.orderBy(
        this.flats.filter(f => {
          let ok = true
          // особняк
          if (filtersTmp[0].selected.length && !this._.includes(filtersTmp[0].selected, f.block))
            ok = false
          // этаж
          if (filtersTmp[1].selected.length && !this._.includes(filtersTmp[1].selected, f.floor))
            ok = false
          // спальни
          if (filtersTmp[2].selected.length && !this._.includes(filtersTmp[2].selected, f.bedrooms))
            ok = false
          // площадь
          if (
            filtersTmp[3].selected.length &&
            (parseFloat(f.area) < filtersTmp[3].selected[0] ||
              parseFloat(f.area) > filtersTmp[3].selected[1])
          )
            ok = false
          // цена
          if (
            filtersTmp[4].selected.length &&
            (f.price < filtersTmp[4].selected[0] || f.price > filtersTmp[4].selected[1])
          )
            ok = false
          // особенности
          if (
            filtersTmp[5].selected.length &&
            ((f.penthouse &&
              filtersTmp[5].selected.length == 1 &&
              !f.terrace &&
              !this._.includes(filtersTmp[5].selected, 1)) ||
              (f.terrace &&
                filtersTmp[5].selected.length == 1 &&
                !f.penthouse &&
                !this._.includes(filtersTmp[5].selected, 2)) ||
              (!f.penthouse && !f.terrace))
          )
            ok = false
          // console.log(!f.penthouse && !f.terrace)

          // console.log(this._.includes(filtersTmp[5].selected, 1))

          return ok
          // return m.category==this.activeCategory
        }),
        "price",
        this.sortingByPrice == "min-max" ? "asc" : "desc"
      )

      return flatsFiltered.slice(0, this.flatsToShow)
    },
    filteredFlatsQty() {
      let filtersTmp = this.mobView ? this.filtersCopy : this.filters

      let flatsFiltered = this._.orderBy(
        this.flats.filter(f => {
          let ok = true
          // особняк
          if (filtersTmp[0].selected.length && !this._.includes(filtersTmp[0].selected, f.block))
            ok = false
          // этаж
          if (filtersTmp[1].selected.length && !this._.includes(filtersTmp[1].selected, f.floor))
            ok = false
          // спальни
          if (filtersTmp[2].selected.length && !this._.includes(filtersTmp[2].selected, f.bedrooms))
            ok = false
          // площадь
          if (
            filtersTmp[3].selected.length &&
            (parseFloat(f.area) < filtersTmp[3].selected[0] ||
              parseFloat(f.area) > filtersTmp[3].selected[1])
          )
            ok = false
          // цена
          if (
            filtersTmp[4].selected.length &&
            (f.price < filtersTmp[4].selected[0] || f.price > filtersTmp[4].selected[1])
          )
            ok = false
          // особенности
          if (
            filtersTmp[5].selected.length &&
            ((f.penthouse &&
              filtersTmp[5].selected.length == 1 &&
              !f.terrace &&
              !this._.includes(filtersTmp[5].selected, 1)) ||
              (f.terrace &&
                filtersTmp[5].selected.length == 1 &&
                !f.penthouse &&
                !this._.includes(filtersTmp[5].selected, 2)) ||
              (!f.penthouse && !f.terrace))
          )
            ok = false
          // console.log(!f.penthouse && !f.terrace)

          // console.log(this._.includes(filtersTmp[5].selected, 1))

          return ok
          // return m.category==this.activeCategory
        }),
        "price",
        this.sortingByPrice == "min-max" ? "asc" : "desc"
      )

      return flatsFiltered.length
    },
    activeFlatInfo() {
      let item = this.flats.filter(f => {
        return f.id === this.activeFlat
      })
      return item[0]
    },
  },

  created() {
    this.flats = this.$store.getters.getFlats
    let priceRange = { min: 100000000, max: 0 }
    let areaRange = { min: 1000, max: 0 }
    this.flats.forEach(f => {
      if (f.price < priceRange.min) priceRange.min = f.price
      if (f.price > priceRange.max) priceRange.max = f.price
      if (parseFloat(f.area) < areaRange.min) areaRange.min = parseFloat(f.area)
      if (parseFloat(f.area) > areaRange.max) areaRange.max = parseFloat(f.area)
    })

    this.filters[3].values = [Math.floor(areaRange.min), Math.ceil(areaRange.max)]
    this.filters[3].selected = [Math.floor(areaRange.min), Math.ceil(areaRange.max)]

    this.filters[4].values = [
      Math.floor(priceRange.min / 1000000) * 1000000,
      Math.ceil(priceRange.max / 1000000) * 1000000,
    ]
    this.filters[4].selected = [
      Math.floor(priceRange.min / 1000000) * 1000000,
      Math.ceil(priceRange.max / 1000000) * 1000000,
    ]

    // start filters (from query string)
    if (this.$route.query.f == "terrace") this.filters[5].selected.push(2)
    if (this.$route.query.f == "penthouse") this.filters[5].selected.push(1)

    this.copyFilters()

    VueRangeSlider.methods.handleKeyup = () => console.log
    VueRangeSlider.methods.handleKeydown = () => console.log
  },

  mounted() {
    window.scrollTo(0, 0)
  },

  methods: {
    moreFlats() {
      this.flatsToShow += flatsPerPage
      if (this.flatsToShow > this.filteredFlatsQty) this.flatsToShow = this.filteredFlatsQty
      // console.log(this.flatsToShow);
    },
    formatRangeTooltip(v) {
      if (this.activeFilter == 5) return Math.round(v / 1000000) + " млн."
      else if (this.activeFilter == 4) return v + " м."
      else return v
    },
    inArray(needle, haystack) {
      var length = haystack.length
      for (var i = 0; i < length; i++) {
        if (haystack[i] == needle) return true
      }
      return false
    },
    activateFilter(id) {
      this.activeFilter = this.activeFilter == id ? 0 : id
    },
    hideFilters() {
      this.activeFilter = 0
    },
    filtersFilled() {
      let filled = false
      this.filters.forEach(el => {
        if (el.selected.length) filled = true
      })
      return filled
    },
    checkFilterValue(filterNum, vID) {
      return this.filters[filterNum].selected.indexOf(vID) != -1
    },
    selectFilterValue(filterNum, filterID, valueID) {
      if (this.filters[filterNum].selected.indexOf(valueID) == -1) {
        this.filters[filterNum].selected.push(valueID)
        this.filters[filterNum].selected = this.filters[filterNum].selected.sort()
      } else
        this.filters[filterNum].selected.splice(
          this.filters[filterNum].selected.indexOf(valueID),
          1
        )
      this.flatsToShow = flatsPerPage
    },
    filterValuesView(filterNum) {
      if (this.filters[filterNum].selected.length) {
        if (
          this.filters[filterNum].values[0] == this.filters[filterNum].selected[0] &&
          this.filters[filterNum].values[1] == this.filters[filterNum].selected[1]
        )
          return ""

        let str = []
        this.filters[filterNum].selected.forEach(el => {
          if (filterNum == 4) str.push(Math.round(el / 1000000))
          else if (filterNum == 5) {
            let val = this.filters[filterNum].values.filter(v => {
              return v.id == el
            })
            str.push(val[0].value)
          } else str.push(el)
        })

        if (filterNum == 4) return str.join(" – ") + " млн."
        else if (filterNum == 3) return str.join(" – ") + " м."
        else return str.join(", ")
      } else return ""
    },
    discardFilters() {
      this.filters.forEach((el, i) => {
        if (el.id == 4 || el.id == 5)
          this.filters[i].selected = [this.filters[i].values[0], this.filters[i].values[1]]
        else this.filters[i].selected = []
      })
      this.copyFilters()
      this.showMobFilters = false
    },
    checkFilterFilled(filterNum) {
      if (this.filters[filterNum].type != "range") {
        return this.filters[filterNum].selected.length != 0
      } else {
        if (
          this.filters[filterNum].values[0] == this.filters[filterNum].selected[0] &&
          this.filters[filterNum].values[1] == this.filters[filterNum].selected[1]
        )
          return false
        else return true
      }
    },
    changeSorting() {
      this.sortingByPrice = this.sortingByPrice == "min-max" ? "max-min" : "min-max"
    },
    flatFeature(flat) {
      return flat.penthouse ? "Пентхаус" : flat.terrace ? "С террасой" : ""
    },
    activateFlat(id) {
      this.activeFlat = this.activeFlat == id ? 0 : id
      this.$store.dispatch("setActiveFlat", this.activeFlat)
      // console.log(this.activeFlat)
    },
    toggleActiveFlatTab(tabNum) {
      this.activeFlatTab = tabNum
    },
    activateMobileFilters() {
      this.showMobFilters = !this.showMobFilters
    },
    applyMobFilters() {
      this.copyFilters()
      this.showMobFilters = false
    },
    discardActiveFlat() {
      this.activeFlat = 0
    },
    copyFilters() {
      this.filtersCopy = []
      this.filters.forEach(el => {
        this.filtersCopy.push({ selected: Array.from(el.selected) })
      })
    },
  },

  components: {
    // Slider,
    VueRangeSlider,
    ApartTableRow,
    // , VueSlider
  },
}
</script>
