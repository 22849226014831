<template>
  <div class="location-page">
    <div class="location-page__text texts">
      Остров Балчуг — романтичное место с тихими переулками, горбатыми мостиками и малоэтажной застройкой,
      что создает атмосферу спокойствия и придает особый шарм.
    </div>
    <div class="location-page__nav tabs-nav">
      <span class="_tab active">Окружение (карта)</span>
      <span
        class="_tab"
        v-scroll-to="{
          el: '#around',
          /* container: '#container', */
          duration: 700,
          /* lazy: false */
          easing: 'ease-out',
          offset: 0,
          /* force: true,
          cancelable: true,
          onStart: onStart,
          onDone: onDone,
          onCancel: onCancel,
          x: false,
          y: true */
        }"
      >Замоскворечье</span>
    </div>
    
    <div class="location-page__map-nav">
      <div class="_categories-wrap">
        <div class="_categories">
          <span
            :class="['btn', cat.id==activeCategory?'active':'']"
            v-for="cat in this.$store.getters.getLocationCategories"
            :key="cat.id"
            @click="selectMapCategory(cat.id)"
          >{{cat.name}}</span>
        </div>
      </div>
      <div class="_link-to-yandex">
        <a :href="yandexMapLink" class="btn btn--arr" target="_blank">Яндекс карты<span class="arrow"><span></span></span></a>
      </div>
    </div>
    
    <div
      class="location-page__map-wrap"
      @click="showXY"
      @touchstart="checkMapSwipeStart"
      @touchend="checkMapSwipeEnd"
      ref="mapRef"
    >
      <div class="location-page__map">
        <transition-group name='fade'>
          <span
            :class="['_map-marker']"
            v-for="m in filteredMarkers"
            :key="m.id"
            :style="{
              left: m.x+'%', top: m.y+'%'
            }"
          >
            <span class="_num">{{m.num}}</span>
            <span class="_name">{{m.name}}</span>
          </span>
        </transition-group>
        <span
          class="_map-marker _map-marker--A"
        >
          <img :src="require('@/assets/img/map-A-part-1.svg')" /><span class="_name"><img :src="require('@/assets/img/map-A-part-2.svg')" /></span></span>
      </div>
    </div>
    <transition name='fade'>
      <div class="location-page__mob-hint-wrap" v-if="$mobView && !mapTouched">
        <span class="location-page__mob-hint">
          <span class="arrow arrow--left"><span></span></span>
          <span class="_txt">Двигай</span>
          <span class="arrow arrow--right"><span></span></span>
        </span>
      </div>
    </transition>
    
    <div class="location-page__mob-link-to-yandex" v-if="$mobView">
      <a
        :href="yandexMapLink"
        class="btn"
      >Посмотреть на Яндекс картах</a>
    </div>

    <ScrollToBlock id="around">
      <div class="location-page__around">
      <div class="_block-1 photo-text-block">
        <div class="_photo" :style="{ width: '65%' }">
          <ImageReveal :source="require('@/assets/img/home/1.jpg')" :title="'Атмосфера Замоскворечья'" :side="'bottom'" />
        </div>
        <div class="_text">
          <p>
            Замоскворечье — один из немногих районов Москвы,
            в котором сохранилась преимущественно малоэтажная застройка, исторические здания и тихие улицы.
          </p>
          <p>
            Здесь практически нет шумных барно-ресторанных локаций, оживленного трафика и большого количества людей. Тишина и атмосфера – преимущество района.
          </p>
        </div>
      </div>
      <div class="_block-2 photo-text-block photo-text-block--right">
        <div class="_text">
          <p>
            Жить у воды - это особая роскошь.
            Находясь между набережными Москва-реки и Водоотводного канала,
            жители А.Residence смогут гулять вечерами по набережным,
            наблюдать за закатами, отражающимися в водной глади,
            и при этом оставаться в пешей и транспортной доступности от ключевых мест большого города.
          </p>
          <p>
            Дорога до любого места в Замоскворечье похожа на прогулку по историческому центру любимого европейского города.
          </p>
        </div>
        <div class="_photo" :style="{ width: '41%' }">
          <ImageReveal :source="require('@/assets/img/home/2.jpg')" :title="'Атмосфера Замоскворечья'" :side="'bottom'" />
        </div>
      </div>
      <div class="_block-3 photo-text-block">
        <div class="_photo" :style="{ width: '25%' }">
          <ImageReveal :source="require('@/assets/img/home/3.jpg')" :title="'Атмосфера Замоскворечья'" :side="'bottom'" />
        </div>
      </div>
    </div>
    </ScrollToBlock>
  </div>
</template>


<script>

import ScrollToBlock from '@/components/ScrollToBlock';
import ImageReveal from '@/components/ImageReveal.vue'

export default {
  name: 'Location',
  props: {},
  data() {
    return {
      activeCategory: 1,
      // mapCategories: [
      //   { id: 1, name: 'Рестораны' },
      //   { id: 2, name: 'Продукты' },
      //   { id: 3, name: 'Спорт' },
      //   { id: 4, name: 'Образование' },
      //   { id: 5, name: 'Медицина' },
      //   { id: 6, name: 'Красота' },
      //   { id: 7, name: 'Культура' },
      // ],
      mapMarkers: [],
      mapMarkers2: [
        { id: 1, num: 5, name: 'Джонджоли', category: 1, x: '21.5%', y: '63%' },
        { id: 2, num: 20, name: 'Эль Гаучо', category: 1, x: '28.2%', y: '70.3%' },
        { id: 3, num: 19, name: 'Paulaner Braunhaus', category: 1, x: '26%', y: '76%' },
        { id: 4, num: 17, name: 'Стейк-хаус Бутчер', category: 1, x: '36.5%', y: '72.5%' },
        { id: 5, num: 14, name: 'Рыба мечты', category: 1, x: '57.8%', y: '66%' },
        { id: 6, num: 1, name: 'Хуго', category: 1, x: '44.8%', y: '67.2%' },
        // { id: 7, num: 15, name: 'Хуго', category: 1, x: '47.4%', y: '71.5%' },
        { id: 8, num: 16, name: 'Le carre', category: 1, x: '46.6%', y: '74.7%' },
        { id: 9, num: 18, name: 'Мечта', category: 1, x: '34%', y: '79%' },
        { id: 10, num: 13, name: 'Иль Помидоро', category: 1, x: '66.5%', y: '57.4%' },
        { id: 11, num: 11, name: 'Community', category: 1, x: '74%', y: '54.5%' },
        { id: 12, num: 12, name: 'Карлсон', category: 1, x: '66.5%', y: '48%' },
        { id: 13, num: 2, name: 'Mitzva Bar', category: 1, x: '69%', y: '24%' },
        { id: 14, num: 3, name: 'Северэвер', category: 1, x: '60.4%', y: '35%' },
        { id: 15, num: 4, name: 'Meatless', category: 1, x: '51%', y: '32%' },
        { id: 16, num: 6, name: 'Джонджоли', category: 1, x: '52%', y: '38%' },
        { id: 17, num: 8, name: 'Балчуг Гриль', category: 1, x: '76%', y: '18.2%' },
        { id: 18, num: 7, name: 'Санпиро', category: 1, x: '79.3%', y: '22.5%' },
        { id: 19, num: 9, name: 'Touch Moscow', category: 1, x: '73.2%', y: '27%' },
        { id: 20, num: 10, name: 'Sadovnicheskaya', category: 1, x: '73.5%', y: '33%' },
        { id: 21, num: 21, name: 'Mas Q Menos', category: 1, x: '27%', y: '85.5%' },
        { id: 22, num: 22, name: 'Акапелла', category: 1, x: '28.9%', y: '88.9%' },
        { id: 23, num: 23, name: 'City Bar Restuarant', category: 1, x: '31%', y: '90%' },        
        
        
        { id: 30, num: 6, name: 'Вкусвилл', category: 2, x: '26%', y: '72%' },
        { id: 31, num: 5, name: 'Магнолия', category: 2, x: '27.3%', y: '63.4%' },
        { id: 32, num: 4, name: 'AB Daily', category: 2, x: '49%', y: '50%' },
        { id: 33, num: 3, name: 'BIlla', category: 2, x: '65.5%', y: '46.6%' },
        { id: 34, num: 2, name: 'Вкусвилл', category: 2, x: '65.5%', y: '61.5%' },
        { id: 35, num: 1, name: 'Wine select', category: 2, x: '59%', y: '68%' },
        { id: 36, num: 7, name: 'Азбука Вкуса', category: 2, x: '15.8%', y: '48%' },
        
        
        { id: 40, num: 1, name: 'DA Ballet', category: 3, x: '42.8%', y: '44.5%' },
        { id: 41, num: 2, name: 'Hendrix studio', category: 3, x: '47%', y: '57.5%' },
        { id: 42, num: 3, name: 'Школа танцев La Playa', category: 3, x: '63.4%', y: '58.5%' },
        { id: 43, num: 4, name: 'La Danse', category: 3, x: '38%', y: '74%' },
        { id: 44, num: 5, name: 'Purovel Spa', category: 3, x: '31%', y: '82%' },
        { id: 45, num: 6, name: 'Tay Club', category: 3, x: '47.5%', y: '77.5%' },
        { id: 46, num: 7, name: 'Super Punch Gym', category: 3, x: '51%', y: '75%' },
        { id: 47, num: 8, name: 'Woman Workout', category: 3, x: '43%', y: '55%' },
        { id: 48, num: 9, name: 'Balleta', category: 3, x: '64%', y: '34%' },
        { id: 49, num: 10, name: 'Topstretching', category: 3, x: '60%', y: '31.2%' },
        { id: 50, num: 11, name: 'FitQueen', category: 3, x: '52%', y: '57%' },
        { id: 51, num: 12, name: 'Flexy Way', category: 3, x: '66%', y: '55.4%' },
        { id: 52, num: 13, name: 'Embody', category: 3, x: '80%', y: '63%' },
        { id: 53, num: 14, name: 'F45', category: 3, x: '46.3%', y: '51%' },
        { id: 54, num: 15, name: 'Тема Фитнес', category: 3, x: '46%', y: '67.2%' },
        { id: 55, num: 16, name: 'Reboot East', category: 3, x: '24%', y: '86%' },
        
        { id: 60, num: 1, name: 'Центр лечебной косметологии Даная им. Т. А. Корчевой', category: 5, x: '19%', y: '86.5%' },
        { id: 62, num: 2, name: 'Центр неврологии доктора Шахновича', category: 5, x: '34%', y: '75%' },
        { id: 63, num: 3, name: 'Quantum clinic', category: 5, x: '59%', y: '53%' },
        { id: 64, num: 4, name: 'Клиника Тибет', category: 5, x: '78%', y: '33%' },
        { id: 65, num: 5, name: 'Остеополиклиник', category: 5, x: '55.2%', y: '50.2%' },
        { id: 66, num: 6, name: 'Центр Стоматологической Имплантологии', category: 5, x: '51%', y: '58%' },
        
        { id: 71, num: 1, name: 'Wellcure and Float Studio на Новокузнецкой', category: 6, x: '61%', y: '47%' },
        { id: 72, num: 2, name: 'Салон красоты Elysion', category: 6, x: '58.2%', y: '43.5%' },
        { id: 73, num: 3, name: 'Барбершоп Wall Street', category: 6, x: '54%', y: '48%' },
        { id: 74, num: 4, name: 'Stefani', category: 6, x: '46%', y: '68%' },
        { id: 76, num: 5, name: 'Салон красоты Roshu beauty', category: 6, x: '49%', y: '67%' },
        { id: 75, num: 6, name: 'Салон Красоты Moné', category: 6, x: '55%', y: '68%' },
        { id: 77, num: 7, name: 'Ситизен Барбершоп', category: 6, x: '51%', y: '71%' },
        { id: 78, num: 8, name: 'AiLab', category: 6, x: '24%', y: '82%' },
        
        { id: 80, num: 1, name: 'Третьяковская галерея', category: 7, x: '54%', y: '5%' },
        { id: 81, num: 2, name: 'Дом Музыки', category: 7, x: '34%', y: '86%' },
        { id: 82, num: 3, name: 'Государственный академический Малый театр', category: 7, x: '10.5%', y: '32%' },
        { id: 83, num: 4, name: 'Театр Луны', category: 7, x: '21%', y: '32.5%' },
        { id: 84, num: 5, name: 'Русский духовный театр Глас', category: 7, x: '49%', y: '50%' },
        { id: 85, num: 6, name: 'Community Stage', category: 7, x: '74.5%', y: '54.8%' },
        
        { id: 90, num: 1, name: 'Школа № 1259', category: 4, x: '20%', y: '51%' },
        { id: 91, num: 2, name: 'ГБОУ Школа № 627', category: 4, x: '28%', y: '63%' },
        { id: 92, num: 3, name: 'Яндекс Учебник', category: 4, x: '40%', y: '77%' },
        { id: 93, num: 4, name: 'Школа № 1259', category: 4, x: '54.5%', y: '64%' },
        { id: 94, num: 5, name: 'Киндерстар Вокал', category: 4, x: '64%', y: '58%' },
        { id: 95, num: 6, name: 'ГБОУ Школа № 518', category: 4, x: '68%', y: '53%' },
        { id: 96, num: 7, name: 'Художественная школа Вдохновение', category: 4, x: '24.5%', y: '75.5%' },
        { id: 97, num: 8, name: 'Sun School', category: 4, x: '21.5%', y: '81.2%' },
        
      ],
      mapTouched: false,
      // yandexMapLink: 'https://yandex.ru/maps/?um=constructor%3Aeee4e35644aac9db600d1f5e043f3b4c715fc1543248a4c036c48fa594d211f0&source=constructorLink'
      yandexMapLink: 'https://yandex.ru/maps/?um=constructor%3A2793ec84cbae927ac8c3db0339326b87e662dfe0c6e74cc2ae63c0c90c66b3f2&source=constructorLink'
    }
  },
  computed: {
    filteredMarkers () {
      return this.mapMarkers.filter(m => {
         return m.category==this.activeCategory
      })
    },
    mobView () {
      return window.innerHeight<=840 ? true : false;
    }
  },
  created () {
    this.mapMarkers = this.$store.getters.getLocationPoints
    // alert(this.$store.getters.getLocationPoints)
    
    // fetch(this.$store.getters.getApiUrl+'convert_points', {
    //   method: 'POST',
    //   headers: {
    //     'Accept': 'application/json',
    //     'Content-Type': 'application/json'
    //   },
    //   body: JSON.stringify(this.mapMarkers2)
    // });

  },
  methods: {
    selectMapCategory (id) {
      this.activeCategory = id;
    },
    ScrollToAround () {
      this.$refs.around.scrollTo();
    },
    showXY (evt) {
      console.log(
        (evt.x/window.innerWidth)*100,
        ((evt.y - this.$refs.mapRef.offsetTop + window.top.scrollY) / this.$refs.mapRef.clientHeight)*100
      )
    },
    handleScroll (evt, el) {
      if (window.scrollY > 500) {
        el.setAttribute(
          'style',
          'opacity: 1; transform: translate3d(0, -10px, 0)'
        )
      }
      return window.scrollY > 100
    },
    checkMapSwipeStart (evt) {
      this.mapSwipeX = evt.changedTouches[0].clientX;
      this.mapSwipeY = evt.changedTouches[0].clientY;
    },
    checkMapSwipeEnd (evt) {
      // если свайп вертикальный, выходим
      let x_l = evt.changedTouches[0].clientX - this.mapSwipeX;
      if (x_l<0) x_l = x_l*-1;
      let y_l = evt.changedTouches[0].clientY - this.mapSwipeY;
      if (y_l<0) y_l = y_l*-1;
      if (x_l < y_l) return false;
      
      this.mapTouched = true;
    },
  },
  mounted () {
    window.scrollTo(0, 0)
  },
  components: {
    ScrollToBlock, ImageReveal
  }
}
</script>