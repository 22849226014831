<template>
  <div class="tabs-slider">
    <div class="tabs-nav">
      <div
        :class="['_tab', currentTextNum==i?'active':'']"
        v-for="(block, i) in texts"
        :key="i"
        @click="selectTab(i)"
      >
        {{block.name}}
      </div>
    </div>
    <div
      class="tabs-content"
      ref="tabsContent"
      :style="{
        'min-height': tabsMinHeight+'px'
      }"
    >
      <transition-group name="texts">
        <div
          v-for="number in [currentTextNum]"
          :key="number"
        >
          <div class="texts" v-html="texts[number].text" />
          <transition name="fade-fastly">
            <div
              class="texts"
              v-if="texts[number].text2 && fullTexts.indexOf(number)!==-1"
              v-html="texts[number].text2"
            />
          </transition>
          <div class="_more-text" v-if="texts[number].text2 && fullTexts.indexOf(number)==-1">
            <span class="btn" @click="openFullText(number)">Читать дальше</span>
          </div>
        </div>
      </transition-group>
    </div>
    
    <div class="slider-wrapper agile-wrapper">
      <div
        class="slider-hover slider-hover--left"
        @click="agileMove('left')"
        v-on:mousemove="updateArrowCoordinates"
        v-if="photos.length>1"
      >
        <span
          class="_cursor-arrow"
          :style="{left: cursorArrowCoords.x+'px', top: cursorArrowCoords.y+'px'}"
        >
          <span class="arrow"><span></span></span>
        </span>  
      </div>
      <div
        class="slider-hover slider-hover--right"
        @click="agileMove('right')"
        v-on:mousemove="updateArrowCoordinates"
        v-if="photos.length>1"
      >
        <span class="_cursor-arrow"
          :style="{left: cursorArrowCoords.x+'px', top: cursorArrowCoords.y+'px'}"
        >
          <span class="arrow"><span></span></span>
        </span>
      </div>
    
      <agile :dots="false" :nav-buttons="false" ref="agileSliderRef" v-if="photos.length>1">
          <div
            class="slide _slide"
            v-for="(photo, i) in photos"
            :key="i"
          >
            <img :src="photo.src" />
            <div class="_slide-info">
              <span class="_num">{{i+1}} ({{photos.length}})</span>{{photo.title}}
            </div>
          </div>
      </agile>
      <!-- <div v-else>
        <div
          class="_slide _one-slide"
          v-for="(photo, i) in photos"
          :key="i"
        >
          <img :src="photo.src" :style="photo.or=='v'?'max-width: 500px;':''" />
        </div>
      </div> -->
    
      <div
        style="display: none;"
        class="slider"
        @touchstart="startSwipe"
        @touchend="endSwipe"
      >
        <div class="_scroll" :style="{
          marginLeft:
            mobView
              ? 'calc(' + (-1*currentNum*100) + 'vw ' + ' + ' + (48*currentNum) + 'px - ' + (16*currentNum) + 'px)'
              : 'calc(' + (-1*currentNum*70) + 'vw ' + ' - ' + (20*currentNum) + 'px)'
        }">
          <div
            class="_slide"
            v-for="(block, i) in texts"
            :key="i"
          >
            <img :src="block.photo" />
            <div class="_slide-info">
              <span class="_num">{{i+1}} ({{texts.length}})</span>{{block.title}}
            </div>
          </div>
          <div class="_slide" @click="selectTab(0)" v-if="texts.length>1">
            <img :src="texts[0].photo" />
            <div class="_slide-info">
              <span class="_num">1 ({{texts.length}})</span>{{texts[0].title}}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import { VueAgile } from 'vue-agile'

export default {
  name: 'TabsSlider',
  props: {
    texts: {
      type: Array
    },
    photos: {
      type: Array
    }
  },
  data () {
    return {
      currentNum: 0,
      currentTextNum: 0,
      fullTexts: [],
      tabsMinHeight: 0,
      cursorArrowCoords: {'x': 0, 'y': 0}
    }
  },
  computed: {
    mobView () {
      return window.innerHeight<=840 ? true : false;
    },
    showArrows () {
      return this.cursorArrowCoords.x > 0 && this.cursorArrowCoords.y > 0
    }
  },
  methods: {
    agileMove (side) {
      // console.log(side)
      side=='left' ? this.$refs.agileSliderRef.goToPrev() : this.$refs.agileSliderRef.goToNext()
      // if (side=='left') this.currentNum--;
      // else if (side=='right') this.currentNum++;
    },
    selectTab (num) {
      this.currentTextNum = num;
    },
    openFullText (num) {
      if (this.fullTexts.indexOf(num)==-1)
        this.fullTexts.push(num);
      else
        this.fullTexts.splice(this.fullTexts.indexOf(num), 1);
    },
    startSwipe (evt) {
      this.swipeX = evt.changedTouches[0].clientX;
      this.swipeY = evt.changedTouches[0].clientY;
    },
    endSwipe (evt) {
      // если свайп вертикальный, выходим
      let x_l = evt.changedTouches[0].clientX - this.swipeX;
      if (x_l<0) x_l = x_l*-1;
      let y_l = evt.changedTouches[0].clientY - this.swipeY;
      if (y_l<0) y_l = y_l*-1;
      if (x_l < y_l) return false;
      
      if (evt.changedTouches[0].clientX < this.swipeX) {
        if (this.currentNum<this.texts.length-1) this.currentNum++;
        evt.preventDefault();
      }
      else if (evt.changedTouches[0].clientX > this.swipeX) {
        if (this.currentNum>0) this.currentNum--;
        evt.preventDefault();
      }
      
    },
    updateArrowCoordinates (event) {
      this.cursorArrowCoords.x = event.clientX;
      this.cursorArrowCoords.y = event.clientY;
    },
    hideSliderArrows () {
      this.cursorArrowCoords.x = 0;
      this.cursorArrowCoords.y = 0;
    },
  },
  mounted () {
    this.tabsMinHeight = this.$refs.tabsContent.clientHeight;
  },
  components: {
    agile: VueAgile
  }
}

</script>