<template>
  <span ref="component">
        <slot/>
    </span>
</template>

<script>
export default {
  name: 'ScrollToBlock',
  props: {
    alignToTop: {
      required: false,
      default: null,
      type: Boolean,
    },
    options: {
      required: false,
      default: null,
      type: Object,
    },
  },
  methods: {
    scrollTo () {
      this.$refs.component.scrollIntoView({behavior: 'smooth'});
    },
  },
};
</script>