<template>
  <div class="special-page">
    <div class="special-page__intro">
      <div class="_text texts">
        A.Residence создает новое качество жизни в центре города. Просторные пентхаусы с бесконечным объемом света и уютный лаунж на террасах апартаментов — наслаждайтесь атмосферой своего гостеприимного дома.
      </div>
      <div class="_img">
        <img :src="require('@/assets/img/photo-samples/formats.jpg')" alt="" />
      </div>
    </div>
    
    <div v-for="special in this.$store.getters.getSpecials" :key="special.id">
      
      <div class="special-page__type">
        <div class="_title">
          <span>{{special.title}}</span>
        </div>
        <div class="_text" v-html="special.text" />
        <div class="_go">
          <router-link
            :to="special.id==1 ? '/aparts?f=penthouse' : '/aparts?f=terrace'"
            class="btn btn--l btn--arr blocked"
          >{{special.btn_title}}<span class="arrow"><span></span></span></router-link>
        </div>
      </div>
      
      <AgileWrap :photos="special.gallery" /> 

    </div>

  </div>
</template>


<script>

import AgileWrap from '@/components/AgileWrap.vue'
// import Slider from '@/components/Slider.vue'

export default {
  name: 'Special',
  data () {
    return {
      sliderPhotos1: [
        {photo: require('@/assets/img/photo-samples/special-1.jpg'), title: 'Художественная визуализация пентхауса'},
        {photo: require('@/assets/img/photo-samples/special-1.jpg'), title: 'Художественная визуализация пентхауса'}
      ],
      sliderPhotos2: [
        {photo: require('@/assets/img/photo-samples/f-3.jpg'), title: 'Художественная визуализация апартамента с террасой'},
        {photo: require('@/assets/img/photo-samples/f-3.jpg'), title: 'Художественная визуализация апартамента с террасой'}
      ],
    }
  },
  mounted () {
    window.scrollTo(0, 0)
  },
  computed: {
    showArrows () {
      return this.cursorArrowCoords.x > 0 && this.cursorArrowCoords.y > 0
    }
  },
  methods: {
  },
  components: {
    AgileWrap,
    // Slider
  }
}

</script>