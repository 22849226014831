<template>
  <div class="documents-as-list">
    <div class="documents-table">
      <div class="_row _row--empty">
        <div>Название</div>
        <div>Дата загрузки</div>
      </div>
      <div class="_row" v-for="doc in this.$store.getters.getDocuments" :key="doc.id">
        <a :href="doc.file_url" class="_activator">
          <div class="_cols">
            <div>
              {{doc.name}}
            </div>
            <div class="_date">
              {{doc.publish_date}}
            </div>
          </div>
          <span class="arrow arrow--down"><span></span></span>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DocumentsList',
  
  data() {
    return {
      
    }
  }
}
</script>