<template>
  <div class="aparts-on-map">
    <div class="_section-1" id="section-1">
      <div class="_block">
        <div class="_title">
          Особняк {{activeBlock}}
        </div>
        <div class="_blocks-to-select-wrap">
          <div class="_blocks-to-select">
            <div
              :class="['_block-rect', '_block-rect--'+block.id, block.id==activeBlock?'--active':'']"
              v-for="block in blocks"
              :key="block.id"
              @click="selectBlock(block.id)"
            >
              {{block.id}}
              <span v-if="block.id==1" class="_label _label--bottom">Садовнический пер.</span>
            </div>
            <span class="_label _label--left">Садовническая ул.</span>
            <span class="_label _label--right">Садовническая наб.</span>
          </div>
        </div>
        <div class="_block-info">
          <div>
            {{blockAparts.length | declension(['апартамент', 'апартамента', 'апартаментов'])}}<span v-if="blockPenthouses.length">,<br />{{blockPenthouses.length | declension(['пентхаус', 'пентхауса', 'пентхаусов'])}}</span>
          </div>
          <div>
            {{blockMetersInfo}}
          </div>
        </div>
        <!-- <span class="_label _label--left">Садовническая ул.</span>
        <span class="_label _label--right">Садовническая ул.</span> -->
      </div>
      <div class="_floors">
        <div class="_title">
          Этаж {{hoveredFloor!=0?hoveredFloor:''}}
        </div>
        <!-- <div class="_floor-info" v-if="hoveredFloor" v-html="floorTextInfo" /> -->
        <div :class="['_facade', '_facade--'+activeBlockInfo.id]">
          <img class="_img" :src="activeBlockInfo.facade" />
          <div
            v-if="hoveredFloor>0"
            class="_floor-highlight"
            :style="hoverFloorInfo.style"
          />
          <div
            class="_floor-hover"
            v-for="floor in activeBlockInfo.floors"
            :key="floor.num"
            :style="floor.style"
            @mouseover="highlightFloor(floor.num)"
            @click="selectFloor(floor.num)"
          >
            <div
              :class="[
                '_floor-info-2',
                activeBlockInfo.id==2 ? '_floor-info-2--right' : '',
              ]"
              v-if="hoveredFloor && hoveredFloor==floor.num"
              v-html="floorTextInfo"></div>
          </div>
        </div>
      </div>
    </div>
    <div id="floor-flats">
      <transition name="fade-fastly">
        <div class="_section-2" v-if="activeBlock">
          <div class="_title">
            Особняк {{activeBlock}}<span v-if="activeFloor">, Этаж {{activeFloor}}</span>
          </div>
          <div class="_choose-type">
            <span
              v-if="activeFloor"
              :class="['btn', flatsViewType=='plan'?'active':'']"
              @click="toggleViewType('plan')"
            >На плане этажа</span>
            <span
              :class="['btn', 'btn--lines', flatsViewType=='list'?'active':'']"
              @click="toggleViewType('list')"
            >Списком<span class="_lines"></span></span>
          </div>
          <div v-if="flatsViewType=='list'" class="aparts-table">
            <ApartTableRow
              v-for="flat in filteredFlats"
              :key="flat.id"
              :flat="flat"
              :activateFlat="activateFlat"
              :activeFlat="activeFlat"
              :discardActiveFlat="discardActiveFlat"
            />
          </div>
          <div v-if="flatsViewType=='plan' && activeFloorInfo" class="aparts-floor-plan">
            <div class="_info">
              <div v-if="hoveredFlatOnPlan">
                <div class="_title">
                  Апартамент №{{hoveredFlatInfo.title}}
                </div>
                <div class="_other">
                  <div>
                    {{hoveredFlatInfo.bedrooms | declension(['спальня', 'спальни', 'спальни'])}}
                  </div>
                  <div>
                    {{hoveredFlatInfo.area}} м<sup>2</sup>
                  </div>
                  <div>
                    {{flatFeature(hoveredFlatInfo)}}
                  </div>
                  <div class="_price-row">
                    {{hoveredFlatInfo.price | thousands}} руб.
                  </div>
                </div>
              </div>
              <div class="_blocks-scheme">
                <div class="_blocks-to-select">
                  <div
                    :class="['_block-rect', '_block-rect--'+block.id, block.id==activeBlock?'--active':'']"
                    v-for="block in blocks"
                    :key="block.id"
                  >
                    {{block.id}}
                    <span v-if="block.id==1" class="_label _label--bottom">Садовнический пер.</span>
                  </div>
                  <span class="_label _label--left">Садовническая ул.</span>
                  <span class="_label _label--right">Садовническая наб.</span>
                </div>
              </div>
            </div>
            <div class="_plan">
              <img class="_compass" :src="require('@/assets/img/compass-desktop-new.svg')" />
              <div class="_plan-img-wrap">
                
                <span class="street-label street-label--top">{{activeBlockInfo.streets.top}}</span>
                <span class="street-label street-label--bottom">{{activeBlockInfo.streets.bottom}}</span>
                <span class="street-label street-label--right"><span>{{activeBlockInfo.streets.right}}</span></span>
                
                <img :src="activeFloorInfo.planPhoto" />

                <div
                  v-for="(lock, lock_num) in activeFloorHoverSold"
                  :key="lock_num"
                  :style="lock.style+'; height: '+lock.height"
                  class="_locked-flat"
                >
                  <svg
                    version="1.1" xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    class="_flat-shape"
                    width="100%"
                    :viewBox="lock.viewBox"
                    style="left: 0; top: 0;"
                  >
                    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                      <path :d="lock.path" />
                    </g>
                  </svg>
                  <img
                    :src="require('@/assets/img/sold.svg')"
                    :alt="lock.flat_title"
                    class="_sold-icon"
                  />
                </div>
                
                <svg
                  v-for="(svg, i) in activeFloorHovers"
                  :key="i+100"
                  version="1.1" xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                  :class="[
                    '_flat-shape',
                    activeFlat==svg.id ? 'active' : '',
                    hoveredFlatOnPlan==svg.id ? 'hovered' : ''
                  ]"
                  :width="svg.width"
                  :viewBox="svg.viewBox"
                  :style="svg.style"
                >
                  <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                    <path
                      @mouseleave="flatPlanNoHover"
                      @mouseover="flatPlanHover(svg.id)"
                      @click="activatePlanFlat(svg.id)"
                      :d="svg.path"
                      :style="{
                        
                      }"
                    ></path>
                  </g>
                </svg>
              </div>
            </div>
          </div>

          <div id="active-plan-flat">
            <div v-if="activeFlat && activeFloorInfo && flatsViewType=='plan'" class="_active-map-flat">
              <ApartTableRow
                :flat="activeFlatInfo"
                :activateFlat="activateFlat"
                :activeFlat="activeFlat"
                :activeStateOnly="true"
                :discardActiveFlat="discardActiveFlat"
              />
            </div>
          </div>

          <div
            class="_return btn btn--light"
            @click="returnToFloors"
          >
            Вернуться к выбору
          </div>
        </div>
      </transition>
    </div>    
  </div>
</template>


<script>

// import Slider from '@/components/Slider.vue'
import ApartTableRow from '@/components/ApartTableRow.vue'

// import 'vue-range-component/dist/vue-range-slider.css'
// import VueRangeSlider from 'vue-range-component'

var VueScrollTo = require('vue-scrollto');

export default {
  name: 'ApartsOnMap',
  data() {
    return {
      activeBlock: 1,
      hoveredFloor: 0,
      activeFloor: 0,
      activeFlatTab: 1,
      flatsViewType: 'list',
      activeFlat: 0,
      hoveredFlatOnPlan: 0,
      blocks: [
        {
          id: 1,
          facade: require('@/assets/img/facades/new/1.2.svg'),
          streets: {
            top: 'Садовнический переулок',
            bottom: 'Внутренний двор',
            right: 'Садовническая улица'
          },
          floors: [
            {
              num: 2,
              style: { bottom: '18.1%', height: '16.7%', width: '100%', left: 0 },
                planPhoto: require('@/assets/img/floor-plans/A1/A1-2-plan.png')
            },
            {
              num: 3, style: { bottom: '34.8%', height: '16.4%', width: '100%', left: 0 },
              planPhoto: require('@/assets/img/floor-plans/A1/A1-3-plan.png')
            },
            {
              num: 4, style: { bottom: '51.2%', height: '16.4%', width: '100%', left: 0 },
              planPhoto: require('@/assets/img/floor-plans/A1/A1-4-plan.png')
            },
            {
              num: 5, style: { bottom: '73%', height: '17.5%', width: '76.8%', left: '16%' },
              planPhoto: require('@/assets/img/floor-plans/A1/A1-5-plan.png')
            },
          ],
        },
        {
          id: 2,
          facade: require('@/assets/img/facades/new/2.2.svg'),
          streets: {
            top: 'Садовническая набережная',
            bottom: 'Внутренний двор',
            right: 'Садовнический переулок'
          },
          floors: [
            {
              num: 2,
              style: { bottom: '10.5%', height: '13%', width: '64.2%', left: 0 },
              planPhoto: require('@/assets/img/floor-plan-sample.png')
            },
            { num: 3, style: { bottom: '23.5%', height: '11.5%', width: '64.2%', left: 0 }, planPhoto: require('@/assets/img/floor-plan-sample.png') },
            { num: 4, style: { bottom: '34.5%', height: '11.5%', width: '64.2%', left: 0 }, planPhoto: require('@/assets/img/floor-plan-sample.png') },
            { num: 5, style: { bottom: '46%', height: '11%', width: '64.2%', left: 0 }, planPhoto: require('@/assets/img/floor-plan-sample.png') },
            { num: 6, style: { bottom: '57%', height: '11.3%', width: '64.2%', left: 0 }, planPhoto: require('@/assets/img/floor-plan-sample.png') },
            { num: 7, style: { bottom: '68%', height: '13%', width: '64.2%', left: 0 }, planPhoto: require('@/assets/img/floor-plan-sample.png') },
            { num: 8, style: { bottom: '81.6%', height: '11.8%', width: '39%', left: '22.3%' }, planPhoto: require('@/assets/img/floor-plan-sample.png') },
          ],
        },
        {
          id: 3,
          facade: require('@/assets/img/facades/new/3.2.svg'),
          streets: {
            top: 'Садовническая набережная',
            bottom: 'Садовническая улица',
            right: 'Внутренний двор'
          },
          floors: [
            {
              num: 2,
              style: { bottom: '10%', height: '11%', width: '66%', right: '1%' },
              planPhoto: require('@/assets/img/floor-plans/A3/A3-2-plan.png')
            },
            {
              num: 3,
              style: { bottom: '21.5%', height: '9.5%', width: '66%', right: '1%' },
              planPhoto: require('@/assets/img/floor-plans/A3/A3-3-plan.png')
            },
            {
              num: 4,
              style: { bottom: '31.5%', height: '9.5%', width: '66%', right: '1%' },
              planPhoto: require('@/assets/img/floor-plans/A3/A3-4-plan.png')
            },
            {
              num: 5,
              style: { bottom: '41.5%', height: '9.5%', width: '66%', right: '1%' },
              planPhoto: require('@/assets/img/floor-plans/A3/A3-5-plan.png')
            },
            {
              num: 6,
              style: { bottom: '51.5%', height: '9.5%', width: '66%', right: '1%' },
              planPhoto: require('@/assets/img/floor-plans/A3/A3-6-plan.png')
            },
            {
              num: 7,
              style: { bottom: '61.5%', height: '9.5%', width: '66%', right: '1%' },
              planPhoto: require('@/assets/img/floor-plans/A3/A3-7-plan.png')
            },
            {
              num: 8,
              style: { bottom: '71.5%', height: '11.5%', width: '66%', right: '1%' },
              planPhoto: require('@/assets/img/floor-plans/A3/A3-8-plan.png')
            },
            {
              num: 9,
              style: { bottom: '83%', height: '17%', width: '54.5%', right: '7.7%' },
              planPhoto: require('@/assets/img/floor-plans/A3/A3-9-plan.png')
            },
          ],
        },
        {
          id: 4,
          facade: require('@/assets/img/facades/new/4.2.svg'),
          streets: {
            top: 'Внутренний двор',
            bottom: 'Садовническая улица',
            right: 'Садовнический переулок'
          },
          floors: [
            {
              num: 2,
              style: { bottom: '13.9%', height: '12.8%', width: '52.6%', right: '0.1%' },
              planPhoto: require('@/assets/img/floor-plan-sample.png')
            },
            {
              num: 3,
              style: { bottom: '26.7%', height: '12.6%', width: '52.6%', right: '0.1%' },
              planPhoto: require('@/assets/img/floor-plan-sample.png')
            },
            {
              num: 4,
              style: { bottom: '39.3%', height: '12.6%', width: '52.6%', right: '0.1%' },
              planPhoto: require('@/assets/img/floor-plan-sample.png')
            },
            {
              num: 5,
              style: { bottom: '51.9%', height: '12.6%', width: '52.6%', right: '0.1%' },
              planPhoto: require('@/assets/img/floor-plan-sample.png')
            },
            {
              num: 6,
              style: { bottom: '64.5%', height: '14.33%', width: '52.6%', right: '0.1%' },
              planPhoto: require('@/assets/img/floor-plan-sample.png')
            },
            {
              num: 7,
              style: { bottom: '64.5%', height: '14.33%', width: '52.6%', right: '0.1%' },
              planPhoto: require('@/assets/img/floor-plan-sample.png')
            },
            {
              num: 8,
              style: { bottom: '79%', height: '20.9%', width: '42.4%', right: '8%' },
              planPhoto: require('@/assets/img/floor-plan-sample.png')
            },
          ],
        },
        {
          id: 5,
          facade: require('@/assets/img/facades/new/5.2.svg'),
          streets: {
            top: 'Внутренний двор',
            bottom: 'Садовническая улица',
            right: 'Садовнический переулок'
          },
          floors: [
            {
              num: 2,
              style: { bottom: '13.9%', height: '12.4%', width: '96.8%', right: '1.7%' },
              planPhoto: require('@/assets/img/floor-plans/A5/A5-2-plan.png')
            },
            {
              num: 3,
              style: { bottom: '26.3%', height: '12.3%', width: '96.8%', right: '1.7%' },
              planPhoto: require('@/assets/img/floor-plans/A5/A5-3-plan.png')
            },
            {
              num: 4,
              style: { bottom: '38.7%', height: '12.3%', width: '96.8%', right: '1.7%' },
              planPhoto: require('@/assets/img/floor-plans/A5/A5-4-plan.png')
            },
            {
              num: 5,
              style: { bottom: '51.1%', height: '12.3%', width: '96.8%', right: '1.7%' },
              planPhoto: require('@/assets/img/floor-plans/A5/A5-5-plan.png')
            },
            {
              num: 6,
              style: { bottom: '63.5%', height: '12.3%', width: '96.8%', right: '1.7%' },
              planPhoto: require('@/assets/img/floor-plans/A5/A5-6-plan.png')
            },
            {
              num: 7,
              style: { bottom: '80%', height: '20%', width: '82%', right: '9.1%' },
              planPhoto: require('@/assets/img/floor-plans/A5/A5-7-plan.png')
            },
          ],
        }
      ],
      flats: [],
      flatHovers: []
    }
  },
  created () {
    this.flats = this.$store.getters.getFlats
    this.flatHovers = this.$store.getters.getFlatHovers
  },
  computed: {
    activeBlockInfo () {
      let item = this.blocks.filter(b => {
        return b.id === this.activeBlock
      })
      return item[0]
    },
    blockFlats () {
      return this.flats.filter(f => {
        return f.block==this.activeBlock
      });
    },
    blockAparts () {
      return this.flats.filter(f => {
        return f.block==this.activeBlock && !f.penthouse
      });      
    },
    blockPenthouses () {
      return this.flats.filter(f => {
        return f.block==this.activeBlock && f.penthouse
      });      
    },
    blockMetersInfo () {
      let min = 1000, max = 0;
      this.blockFlats.forEach(f => {
        if (f.area < min) min = f.area
        if (f.area > max) max = f.area
      })
      return Math.floor(min) + '—' + Math.ceil(max) + ' м'
    },
    hoverFloorInfo () {
      let block = this.blocks.filter(b => {
        return b.id === this.activeBlock
      })
      block = block[0]
      let floor = block.floors.filter(floor => {
        return floor.num === this.hoveredFloor
      })
      return floor[0]      
    },
    activeFloorInfo () {
      let block = this.blocks.find(b => {
        return b.id === this.activeBlock
      })
      let floor = block.floors.find(floor => {
        return floor.num === this.activeFloor
      })
      if (floor!=undefined) {
        let floor_ = floor;
        floor_.block = block.id;
        return floor_        
      } else return false
    },
    activeFloorHovers () {
      return this.flatHovers.filter(hover => {
        return hover.block == this.activeBlock && hover.floor == this.activeFloor && (hover.status==1 || hover.status==5)
      })
    },
    activeFloorHoverSold () {
      return this.flatHovers.filter(hover => {
        return hover.block == this.activeBlock && hover.floor == this.activeFloor && hover.status!=1 && hover.status!=5
      })
    },
    activeFlatInfo () {
      return this.flats.find(f => {
        return f.id === this.activeFlat
      })
    },
    hoveredFlatInfo () {
      return this.flats.find(f => {
        return f.id === this.hoveredFlatOnPlan
      })
    },
    filteredFlats () {
      return this.blockFlats.filter(f => {
        return !this.activeFloor || f.floor === this.activeFloor
      })
    },
    floorTextInfo () {
      let min = 1000, max = 0, flatsQty = 0;
      this.blockFlats.forEach(f => {
        if (f.floor == this.hoveredFloor) {
          flatsQty++;
          if (f.area < min) min = f.area
          if (f.area > max) max = f.area          
        }
      })
      
      let labels = [];
      // console.log(this.hoveredFloor);
      if (this.hoveredFloor == this.activeBlockInfo.floors.length+1)
        labels = ['пентхаус', 'пентхауса', 'пентхаусов'];
      else
        labels = ['апартамент', 'апартамента', 'апартаментов'];
      
      let meters = Math.floor(min) + '—' + Math.ceil(max) + ' м'
      if (Math.ceil(max) - Math.floor(min) == 1 || Math.ceil(max) - Math.floor(min) == 0)
        meters = Math.floor(min) + ' м'
      
      if (min==1000 && max==0)
        return (this.$options.filters.declension(flatsQty, labels))
      else
        return (this.$options.filters.declension(flatsQty, labels))
          + '<br />' + meters
    }
  },
  methods: {
    selectBlock (id) {
      this.activeBlock = id
      this.activeFlat = 0
      this.hoveredFlatOnPlan = 0
      this.hoveredFloor = 0
      this.activeFloor = 0
      this.flatsViewType = 'list'
    },
    highlightFloor (floorNum) {
      this.hoveredFloor = floorNum
    },
    selectFloor (floorNum) {
      this.activeFloor = floorNum
      this.activeFlat = 0
      this.hoveredFlatOnPlan = 0
      VueScrollTo.scrollTo('#floor-flats', 800, {
        // el: '#start',
        // duration: 800,
        easing: 'ease-in-out',
        offset: 0,
      })
    },
    toggleViewType (t) {
      this.flatsViewType = t
    },
    discardActiveFlat () {
      this.activeFlat = 0
    },
    returnToFloors () {
      this.hoveredFloor = 0
      this.activeFloor = 0
      this.activeFlat = 0
      this.hoveredFlatOnPlan = 0
      this.flatsViewType = 'list'
      VueScrollTo.scrollTo('#section-1', 800, {
        easing: 'ease-in-out',
        offset: -90,
      })
    },
    activateFlat (id) {
      this.activeFlat = (this.activeFlat==id) ? 0 : id
      this.$store.dispatch('setActiveFlat', this.activeFlat)
    },
    flatFeature (flat) {
      return (flat.penthouse ? 'Пентхаус' : (flat.terrace ? 'С террасой' : ''))
    },
    flatPlanHover (id) {
      this.hoveredFlatOnPlan = id;
    },
    flatPlanNoHover () {
      this.hoveredFlatOnPlan = 0
    },
    activatePlanFlat (id) {
      this.hoveredFlatOnPlan = id
      if (this.activeFlat!=id) {
        this.activateFlat(id)
        setTimeout(function() {
          VueScrollTo.scrollTo('#active-plan-flat', 800, {
            easing: 'ease-in-out',
            offset: -40,
          })        
        }, 300);        
      }
    }
  },
  components: {
    ApartTableRow
  }
}

</script>
