<template>
  <div class="select-page">
    <div class="select-page__intro">
      <div class="texts">
        Окна большинства апартаментов выходят на разные стороны света, чтобы обеспечить светлое и комфортное пространство. Все апартаменты предлагаются с готовой отделкой в двух вариантах — светлом и темном. Пентхаусы в состоянии Shell&Core.
      </div>
      
      <div class="tabs-nav" v-if="!this.$mobView">
        <span :class="['_tab', viewType=='list'?'active':'']" @click="viewTypeChange('list')">Выбор по параметрам</span>
        <span :class="['_tab', viewType=='map'?'active':'']" @click="viewTypeChange('map')">Выбор по генплану</span>
      </div>
    </div>
    
    <div :class="['aparts-select-tool', 'aparts-select-tool--'+viewType]">
      <transition name="tab">
        <ApartsList v-if="viewType=='list'" />
        <ApartsMap v-else-if="viewType=='map'" />
      </transition>
    </div>
  </div>
</template>


<script>

import ApartsList from '@/components/ApartsList';
import ApartsMap from '@/components/ApartsMap';

export default {
  name: 'Aparts',
  data() {
    return {
      viewType: 'list',
    }
  },
  mounted () {
    window.scrollTo(0, 0)
  },
  methods: {
    viewTypeChange (name) {
      this.viewType = name;
    }
  },
  components: {
    ApartsList, ApartsMap
  }
}
</script>