<template>
  <div class="slider-wrapper agile-wrapper">
    
    <div
      class="slider-hover slider-hover--left"
      @click="agileMove('left', 'agileSliderRef1')"
      v-on:mousemove="updateArrowCoordinates"
      v-if="photos.length>1"
    >
      <span
        class="_cursor-arrow"
        :style="{left: cursorArrowCoords.x+'px', top: cursorArrowCoords.y+'px'}"
      >
        <span class="arrow"><span></span></span>
      </span>  
    </div>
    <div
      class="slider-hover slider-hover--right"
      @click="agileMove('right', 'agileSliderRef1')"
      v-on:mousemove="updateArrowCoordinates"
      v-if="photos.length>1"
    >
      <span class="_cursor-arrow"
        :style="{left: cursorArrowCoords.x+'px', top: cursorArrowCoords.y+'px'}"
      >
        <span class="arrow"><span></span></span>
      </span>
    </div>
    
    <agile :fade="fade!=undefined?fade:false" :dots="false" :nav-buttons="false" ref="agileRef" v-if="photos.length>1">
      <div
        class="slide _slide"
        v-for="(photo, i) in photos"
        :key="i"
      >
        <img :src="photo.src!=undefined?photo.src:photo.photo" />
        <div class="_slide-info">
          <span class="_num">{{i+1}} ({{photos.length}})</span>{{photo.title}}
        </div>
      </div>
    </agile>      
  </div>
</template>


<script>

import { VueAgile } from 'vue-agile'

export default {
  name: 'AgileWrap',
  props: {
    photos: {
      type: Array,
      required: true
    },
    fade: {
      type: Boolean,
      required: false
    }
  },
  data () {
    return {
      cursorArrowCoords: { 'x': 0, 'y': 0 }
    }
  },
  mounted () {
  },
  computed: {
    showArrows () {
      return this.cursorArrowCoords.x > 0 && this.cursorArrowCoords.y > 0
    }
  },
  methods: {
    agileMove (side) {
      side=='left' ? this.$refs.agileRef.goToPrev() : this.$refs.agileRef.goToNext()
    },
    updateArrowCoordinates (event) {
      this.cursorArrowCoords.x = event.clientX;
      this.cursorArrowCoords.y = event.clientY;
    },

  },
  components: {
    agile: VueAgile
  }
}

</script>