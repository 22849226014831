<template>
  <div class="about-page">
    <div class="about-page__text">
      <div class="texts">
        A.Residence объединяет 5 особняков, расположенных
        на Садовнической улице в атмосферном районе Замоскворечья 
        на собственной приватной территории.
      </div>
      <div class="_img">
        <img :src="require('@/assets/img/about/1.jpg')" alt="" />
      </div>
    </div>
    
    <TabsSlider
      v-for="about in this.$store.getters.getAbout"
      :key="about.id"
      :texts="about.texts"
      :photos="about.gallery"
    />
    
    <!-- <TabsSlider :blocks="blocksPart1"/>
    <TabsSlider :blocks="blocksPart2"/>
    <TabsSlider :blocks="blocksPart3"/> -->
    
    <InteractForm />
  </div>
</template>

<script>

import InteractForm from '@/components/Interact.vue'
import TabsSlider from '@/components/TabsSlider.vue'

export default {
  name: 'About',
  data () {
    return {
      /*
      blocksPart1: [
        {
          name: 'Архитектура',
          text: 'Бюро «Цимайло Ляшенко и партнеры» предложили создать комплекс в едином архитектурном стиле, но с разной геометрией окон. Благодаря такому решению и переменной этажности каждый дом приобрел свой собственный характер. Фасады домов выполнены из натурального камня, а металлическая отделка окон создает контраст и ритм экстерьера. Современная архитектура элегантно вписана в дизайн-код окружающего района, дополняя, но не нарушая его гармонии.',
          text2: '',
          photo: require('@/assets/img/photo-samples/about-slider-1-1-.jpg'),
          photoTitle: 'Визуализация особых форматов'
        },
        {
          name: 'Отделка',
          text: 'Окна большинства апартаментов выходят на разные части света, чтобы обеспечить светлое и комфортное пространство. Все апартаменты предлагаются с готовой отделкой в двух вариантах – светлом и темном.',
          text2: '',
          photo: require('@/assets/img/photo-samples/f-1.jpg'),
          photoTitle: 'Визуализация особых форматов'
        },
        // {
        //   name: 'Лобби',
        //   text: 'Лобби Бюро «Цимайло Ляшенко и партнеры» предложили создать комплекс в едином архитектурном стиле, но с разной геометрией окон. Благодаря такому решению и переменной этажности каждый дом приобрел свой собственный характер.',
        //   text2: '',
        //   photo: require('@/assets/img/photo-samples/about-slider-1-1-.jpg'),
        //   photoTitle: 'Визуализация особых форматов'
        // },
        {
          name: 'Инженерия',
          text: 'Каждое помещение комплекса A.Residence функционально соответствует потребностям современного жителя мегаполиса и отражает европейский подход к проектированию и строительству.',
          text2: 'Приточно-вытяжная система вентиляции обеспечит приток чистого и свежего воздуха, исключая необходимость открывать окна. Центральное кондиционирование с учетом индивидуального потребления и терморегуляторами в каждом помещении позволит настроить микроклимат под каждого члена семьи. Система контроля доступа, охраны и видеонаблюдения, а также усилители сигнала для высокоскоростного интернета и бесперебойной связи предусмотрены для всей территории.',
          photo: require('@/assets/img/photo-samples/f-1.jpg'),
          photoTitle: 'Визуализация особых форматов'
        },
      ],
      blocksPart2: [
        {
          name: 'Благоустройство',
          text: 'Скрытый от посторонних, изолированный от шума внутренний двор-сад создан немецким архитектурным бюро Udo Dagenbach для идеального отдыха. Даже в холодное время года двор будет зеленым благодаря хвойным деревьям и кустарникам, а с приходом весны сад наполнится палитрой ярких красок и ароматов многолетних цветов.',
          text2: 'Удобные лавочки, приятное декоративное освещение и продуманный до мелочей ландшафтный дизайн с растущими деревьями идеально дополняют атмосферу уюта вашего дома.',
          photo: require('@/assets/img/photo-samples/about-slider-2-1.jpg'),
          photoTitle: 'Визуализация особых форматов'
        },
        {
          name: 'Инфраструктура',
          text: 'На первых этажах A.Residence расположена собственная зона ритейла, которую дополняет развитая инфраструктура бизнес-центра «Аврора», который находится по соседству. Здесь представлены такие кафе и рестораны как «Кофемания», Correas, Paul, «Бутчер». Управляющая компания комплекса возьмет на себя заботу о вашем доме и выполнение хозяйственных поручений.',
          text2: '',
          photo: require('@/assets/img/photo-samples/about-slider-2-1.jpg'),
          photoTitle: 'Визуализация особых форматов'
        },
        {
          name: 'Паркинг',
          text: 'Подземный одноуровневый паркинг вмещает в себя 240 машиномест, в том числе увеличенные парковочные места для автомобилей представительского класса.',
          text2: '',
          photo: require('@/assets/img/photo-samples/about-slider-2-1.jpg'),
          photoTitle: 'Визуализация особых форматов'
        }
      ],
      blocksPart3: [
        {
          name: 'Управляющая компания',
          text: 'Профессиональная управляющая компания – это залог высокого уровня сервиса и качества жизни. Все услуги, которые могут вам понадобиться дома, уже есть на рынке, но мы собрали все в одно удобное приложение, чтобы специалисты управляющей компании могли оперативно приступить к выполнению поручения и сэкономили ваше время.',
          text2: 'Жителям A.Residence будут доступны такие услуги как:<br /><ol><li>Обслуживание дома (клининг, химчистка, доставка, мастер по дому, батлер, няня, флорист, садовник и др.)</li><li>Обслуживание автомобиля (мойка, шиномонтаж, сезонное хранение и др.)</li><li>Личный брокер (поиск и взаимодействие с арендаторами инвестиционной недвижимости).</li></ol>',
          photo: require('@/assets/img/photo-samples/1.jpg'),
          photoTitle: 'Визуализация особых форматов'
        },
      ]
      */
    }
  },
  methods: {
  },
  mounted () {
    window.scrollTo(0, 0)
  },
  components: {
    TabsSlider, InteractForm
  }
}
</script>
