<template>
  <div
    :class="{ '_row': true, 'active': activeFlat==flat.id }"
  >
    <div class="_activator" @click="activateFlat(flat.id)" v-if="!activeStateOnly">
      <div class="_cols">
        <div>
          Особняк {{flat.block}}
        </div>
        <div>
          Этаж {{flat.floor}}
        </div>
        <div>
          {{flat.bedrooms | declension(['спальня', 'спальни', 'спальни'])}}
        </div>
        <div>
          {{flat.area}} м<sup>2</sup>
        </div>
        <div>
          {{flat.price | thousands}} руб.
        </div>
        <div>
          {{flatFeature(flat)}}
        </div>
      </div>
      <span class="plus"></span>
      <div class="_mob-preview" v-if="$mobView">
        <div class="_num">
          № {{flat.title}}
        </div>
        <img :src="flat.plan" />
      </div>
    </div>
    <transition name="fade-fastly">
      <div :class="['desktop-apart-info', 'desktop-apart-info--'+activeFlatTab]" v-if="flat.id==activeFlat">
        
        <div
          class="_left-column"
          ref="leftColumn"
          :style="[
            minHeightForLeftColumn ? 'height: ' + minHeightForLeftColumn + 'px' : ''
          ]
          "
        >
          <div class="tabs-nav">
            <div :class="['_tab', activeFlatTab==1?'active':'']" @click="toggleActiveFlatTab(1)">
              Описание
            </div>
            <div :class="['_tab', activeFlatTab==2?'active':'']" @click="toggleActiveFlatTab(2)">
              Отделка
            </div>
            <div :class="['_tab', activeFlatTab==3?'active':'']" @click="toggleActiveFlatTab(3)" v-if="!activeStateOnly">
              На плане
            </div>
          </div>
          <div class="tabs-content tabs-content--info" v-if="activeFlatTab==1 || activeFlatTab==3">
            <div class="_text-params">
              <div class="_title">
                Апартамент <span class="_num">№ {{flat.title}}</span>
                <div>
                  <div class="_status">
                    {{flatStatus.title}}
                  </div>
                </div>
              </div>
              <div class="_other">
                <div>
                  Особняк {{flat.block}}
                </div>
                <div>
                  Этаж {{flat.floor}}
                </div>
                <div>
                  {{flat.bedrooms | declension(['спальня', 'спальни', 'спальни'])}}
                </div>
                <div>
                  {{flat.area}} м<sup>2</sup>
                </div>
                <div>
                  {{flatFeature(flat)}}
                </div>
                <div>
                  {{flat.price | thousands}} руб.
                </div>
              </div>
            </div>
            <div class="mob-apart-info__actions">
              <span class="btn btn--black btn--arr" @click="showRequest">Записаться на встречу<span class="arrow"><span></span></span></span>
              <a :href="flatPdfUrl" target="_blank" class="btn btn--arr-down">PDF<span class="arrow arrow--down"><span></span></span></a>
            </div>
          </div>
          <div class="tabs-content tabs-content--furnish" v-if="activeFlatTab==2">
            <div class="_text">
              <div v-if="flat.furnish<3">
                Апартамент № {{flat.title}} продается с чистовой отделкой и сантехникой.
                Вид отделки — «{{furnishInfo(flat.furnish).title}}».
              </div>
              <div v-else>
                Пентхаус № {{flat.title}} продается в состоянии Shell&Core (без отделки).
                Особенность лота – терраса, которая проходит вдоль всего периметра пентхауса.
              </div>
              <div v-html="furnishInfo(flat.furnish).text" />
            </div>
            <div class="mob-apart-info__actions">
              <span class="btn btn--black btn--arr" @click="showRequest">Записаться на встречу<span class="arrow"><span></span></span></span>
              <a :href="flatPdfUrl" target="_blank" class="btn btn--arr-down">PDF<span class="arrow arrow--down"><span></span></span></a>
            </div>
            <div
              class="mob-apart-info__actions"
              v-if="this.$store.getters.getBooklet && flat.furnish<3">
              <a
                :href="this.$store.getters.getBooklet"
                class="btn btn--arr"
                target="_blank"
              >Буклет отделочных материалов<span class="arrow"><span></span></span></a>
            </div>
          </div>
        </div>
        
        <div
          class="_right-column"
          
          ref="rightColumn"
        >
          <div class="_plan-wrap" v-if="activeFlatTab==1">
            <div>
              <img class="_plan" :src="flat.plan" />
            </div>
          </div>
          <img v-show="activeFlatTab==1 || activeFlatTab==3" class="_compass" :src="require('@/assets/img/compass-desktop-new.svg')" />
          <div v-if="activeFlatTab==2">
            <AgileWrap :photos="furnishGallery(flat.furnish)" :fade="true" />
          </div>
          <div v-show="activeFlatTab==3 && flat.on_floor!=''" class="_floor-plan-wrap">
            <div class="_img-wrap" ref="flatOnFloorWrap">
              <span
                v-show="onFloorImgLoaded"
                class="street-label street-label--top"
              >{{blockInfo(flat.block).streets.top}}</span>
              <span
                v-show="onFloorImgLoaded"
                class="street-label street-label--bottom"
              >{{blockInfo(flat.block).streets.bottom}}</span>
              <span
                v-show="onFloorImgLoaded"
                class="street-label street-label--right"
                :style="{ marginLeft: onFloorLabelRight + 'px' }"
              ><span>{{blockInfo(flat.block).streets.right}}</span></span>
              <transition name="fade-fastly">
                <img
                  v-if="activeFlatTab==3"
                  class="_plan"
                  :src="flat.on_floor"
                  @load="waitOnFloor"
                />
              </transition>
            </div>
          </div>
        </div>
        
      </div>
    </transition>
    
    <div class="mob-apart-info pop-up" v-if="$mobView && flat.id==activeFlat">
      <div class="_pop-up-content">
        <span class="plus" @click="closeFlatInfo"></span>
        <div class="tabs-nav">
          <div :class="['_tab', activeFlatTab==1?'active':'']" @click="toggleActiveFlatTab(1)">
            Описание
          </div>
          <div :class="['_tab', activeFlatTab==2?'active':'']" @click="toggleActiveFlatTab(2)">
            Отделка
          </div>
          <div :class="['_tab', activeFlatTab==3?'active':'']" @click="toggleActiveFlatTab(3)" v-if="!activeStateOnly">
            На плане
          </div>
        </div>
        <div class="tabs-content tabs-content--info" v-if="activeFlatTab==1">
          <div class="_plan-area">
            <div class="_plan-wrap">
              <div>
                <img class="_plan" :src="flat.plan" />
              </div>
            </div>
            <img class="_compass" :src="require('@/assets/img/compass-desktop-new.svg')" />
          </div>
          <div class="_text-params">
            <div class="_title">
              Апартамент №{{flat.title}}
              <div class="_status">
                <div>
                  {{flatStatus.title}}
                </div>
              </div>
            </div>
            <div class="_other">
              <div>
                Особняк {{flat.block}}
              </div>
              <div>
                Этаж {{flat.floor}}
              </div>
              <div>
                {{flat.bedrooms | declension(['спальня', 'спальни', 'спальни'])}}
              </div>
              <div>
                {{flat.area}} м<sup>2</sup>
              </div>
              <div>
                {{flatFeature(flat)}}
              </div>
              <div>
                {{flat.price | thousands}} руб.
              </div>
            </div>
          </div>
          <div class="mob-apart-info__actions">
            <span class="btn btn--black btn--arr" @click="showRequest">Записаться на встречу<span class="arrow"><span></span></span></span>
            <a :href="flatPdfUrl" target="_blank" class="btn btn--arr-down">PDF<span class="arrow arrow--down"><span></span></span></a>
          </div>
        </div>
        <div class="tabs-content tabs-content--furnish" v-if="activeFlatTab==2">
          
          <div :style="{ overflow: 'hidden' }">
            <agile
              :dots="false"
              :nav-buttons="false"
              :fade="true"
              ref="agileSliderRef"
            >
              <div
                class="slide _slide"
                v-for="(photo, i) in furnishGallery(flat.furnish)"
                :key="i"
              >
                <img :src="photo.src" @click="agileMove('right')" />
                <div class="_slide-info">
                  <span class="_num">{{i+1}} ({{furnishGallery(flat.furnish).length}})</span>{{photo.title}}
                </div>
              </div>
            </agile>
          </div>
          
          <div class="_text">
            <div v-if="flat.furnish<3">
              Апартамент № {{flat.title}} продается с чистовой отделкой и сантехникой.
              Вид отделки — «{{furnishInfo(flat.furnish).title}}».
            </div>
            <div v-else>
              Пентхаус № {{flat.title}} продается в состоянии Shell&Core (без отделки).
              Особенность лота – терраса, которая проходит вдоль всего периметра пентхауса.
            </div>
            <div v-html="furnishInfo(flat.furnish).text" />
          </div>
          <div class="mob-apart-info__actions">
            <span class="btn btn--black btn--arr" @click="showRequest">Записаться на встречу<span class="arrow"><span></span></span></span>
            <a :href="flatPdfUrl" target="_blank" class="btn btn--arr-down">PDF<span class="arrow arrow--down"><span></span></span></a>
          </div>
          <div class="mob-apart-info__actions" v-if="this.$store.getters.getBooklet">
            <a
              :href="this.$store.getters.getBooklet"
              class="btn btn--arr"
              target="_blank"
            >Буклет отделочных материалов<span class="arrow"><span></span></span></a>
          </div>
        </div>
        <div class="tabs-content tabs-content--on-floor" v-if="activeFlatTab==3">
          <span class="street-label street-label--top">{{blockInfo(flat.block).streets.top}}</span>
          <span class="street-label street-label--bottom">{{blockInfo(flat.block).streets.bottom}}</span>

          <transition name="fade-fastly">
            <img
              v-if="activeFlatTab==3"
              class="_plan"
              :src="flat.on_floor"
              @load="waitOnFloor"
            />
          </transition>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

// import Slider from '@/components/Slider.vue'
import AgileWrap from '@/components/AgileWrap.vue'
import { VueAgile } from 'vue-agile'

export default {
  name: 'ApartTableRow',
  props: {
    flat: {
      type: Object,
      required: true
    },
    activeFlat: {
      type: Number,
      required: false
    },
    activateFlat: {
      type: Function,
      required: true
    },
    activeStateOnly: {
      type: Boolean,
      required: false
    },
    discardActiveFlat: {
      type: Function,
      required: true
    }
  },
  data () {
    return {
      activeFlatTab: 1,
      minHeightForLeftColumn: 0,
      onFloorLabelRight: 0,
      onFloorImgLoaded: false,
    }
  },
  watch: { 
    flat: function() {
      // console.log('Prop changed: ', newVal, ' | was: ', oldVal)
      this.activeFlatTab = 1
    }
  },
  computed: {
    flatPdfUrl () {
      return this.$store.state.baseDomain + '/panel/flatpdf/' + this.flat.printId
    },
    flatStatus () {
      return this.$store.getters.getFlatStatuses.find(s => {
        return this.flat.status == s.id
      })
    },
  },
  methods: {
    setLabels () {
      let blockHeight = this.$refs.flatOnFloorWrap.clientHeight;
      if (this.flat.on_floor_sizes_h)
        this.onFloorLabelRight = (blockHeight / this.flat.on_floor_sizes_h) * this.flat.on_floor_sizes_w / 2 + 34;
      else
        this.onFloorLabelRight = 0;
    },
    waitOnFloor () {
      this.setLabels();
      this.onFloorImgLoaded = true;
    },
    flatFeature (flat) {
      return (flat.penthouse ? 'Пентхаус' + (flat.terrace ? ', с террасой' : '') : (flat.terrace ? 'С террасой' : ''))
    },
    blockInfo (blockID) {
      return this.$store.state.blocks.find(b => {
        return b.id==blockID
      })
    },
    furnishInfo (furnishID) {
      return this.$store.getters.getFurnish.find(f => {
        return f.id==furnishID
      })
    },
    furnishGallery (furnishID) {
      let f = this.$store.getters.getFurnish.find(f => {
        return f.id==furnishID
      })
      return f.gallery 
    },
    closeFlatInfo () {
      this.discardActiveFlat()
      this.activeFlatTab = 1
      this.onFloorImgLoaded = false
    },
    toggleActiveFlatTab (tabNum) {
      this.activeFlatTab = tabNum
      if (tabNum==2) {
        this.watchRightColumnHeight()
        // console.log(this.minHeightForLeftColumn)
      } else if (tabNum==3) {
        // console.log(this.$refs.flatOnFloorWrap.clientHeight);
      }
        
    },
    watchRightColumnHeight () {
      if (this.activeFlatTab==2 && this.$refs.rightColumn!=undefined) {
        this.minHeightForLeftColumn = this.$refs.rightColumn.clientHeight
      } else if (this.activeFlatTab==3 && this.$refs.rightColumn!=undefined) {
        // console.log(this.$refs.flatOnFloorWrap.clientHeight)
        this.setLabels();
      } else {
        this.minHeightForLeftColumn = 0
      }
    },
    updateHeight (h) {
      if (this.minHeightForLeftColumn==0) // || this.minHeightForLeftColumn!=h)
        this.minHeightForLeftColumn = h
    },
    showRequest () {
      this.$store.dispatch('changeRequestPopupActive', true)
    },
    agileMove (side) {
      side=='left' ? this.$refs.agileSliderRef.goToPrev() : this.$refs.agileSliderRef.goToNext()
    },
  },
  components: {
    // Slider,
    AgileWrap,
    agile: VueAgile
  },
  mounted () {
    // console.log(this.$refs.rightColumn.clientHeight)
    this.watchRightColumnHeight()
  },
  created() {
    window.addEventListener("resize", this.watchRightColumnHeight);
  },
}

</script>